import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MAT_DIALOG_DATA, MatSort, MatDialog } from '@angular/material';
import { chain } from 'underscore';
import { FacturacionPendienteGrupoSelection } from '../facturacion-pendiente-mo-gru/facturacion-pendiente-mo-gru.component';

@Component({
    selector: 'app-perfect-order-data',
    templateUrl: './facturacion-pendiente-mo.component.html',
    styleUrls: ['./facturacion-pendiente-mo.component.scss']
})
export class FacturacionPendienteSelection implements OnInit {
    fuenteDatos: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChild(MatSort) ordenador: MatSort;
    col: string;
    columnasMostradas =
        ['cliente',
            'importeVenta',
            'importeCompra'];

    constructor(
        @Inject(MAT_DIALOG_DATA) public object: { rastreos: any[], columna: string },
        private ventana: MatDialog) {
    }
 
    ngOnInit(): void {
        const clas = this.object.rastreos;
        this.col = this.object.columna;
        const info = chain(clas).map((info, key) => {
            return {
                'cliente': info.nombreCliente,
                //'proyecto': info.proyecto,
                //'venta': info.venta,
                //'fechaDocumento': info.fechaDocumento,
                'importeVenta': info.importeMonedaDocumento,
                //'compra': info.compra,
                'importeCompra': info.importeCompraMxn,
                //'fechaEnvioCompra': info.fechaEnvioCompra,
                //'jefeProyecto': info.jefeProyecto

            };
        }).value();

        this.fuenteDatos = new MatTableDataSource(info);
        this.fuenteDatos.sort = this.ordenador;

    }

    abrirForma(): void {
        const forma = this.ventana.open(FacturacionPendienteGrupoSelection, {
            data: { rastreos: this.object.rastreos, columnaSel: this.col },
            panelClass: 'form-container',
            width: '85vw'
        });
    }

}