import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatSort } from '@angular/material';
import * as moment from 'moment';
import { chain } from 'underscore';
import { FacturacionPendiente } from 'src/app/entidades/facturacionPendiente';
import { Contexto } from 'src/app/api/contexto.service';

@Component({
    selector: 'app-perfect-order-data',
    templateUrl: './facturacion-pendiente-mo-gru.component.html',
    styleUrls: ['./facturacion-pendiente-mo-gru.component.scss']
})
export class FacturacionPendienteGrupoSelection implements OnInit {
    fuenteDatos: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChild(MatSort) ordenador: MatSort;
    col: string;
    columnasMostradas =
        ['cliente',
            'proyecto',
            'venta',
            'fechaDocumento',
            'importeVenta',
            'compra',
            'importeCompra',
            'fechaEnvioCompra',
            'jefeProyecto',
            'dias'];

    facturacion: FacturacionPendiente[];

    constructor(private ctx: Contexto,
        @Inject(MAT_DIALOG_DATA) public object: { rastreos: any[], columnaSel: string },
        private ventana: MatDialogRef<FacturacionPendienteGrupoSelection>) {
    }

    ngOnInit(): void {

        this.col = this.object.columnaSel;

        const filtroGrupo = this.object.rastreos.filter(c => c.labelClas == this.object.columnaSel);

        const clas = this.object.rastreos
      
        const info = chain(clas).map((info, key) => {
            return {
                'cliente': info.nombreCliente,
                'proyecto': info.proyecto,
                'venta': info.venta,
                'fechaDocumento': info.fechaDocumento,
                'importeVenta': info.importeMonedaDocumento,
                'compra': info.compra,
                'importeCompra': info.importeCompraMxn,
                'fechaEnvioCompra': info.ctFechaEnvioSap,
                'jefeProyecto': info.nombreJefe,
                'dias': moment().diff(info.fechaComprometida, 'days')

            };
        }).value();

        this.fuenteDatos = new MatTableDataSource(info);
        this.fuenteDatos.sort = this.ordenador;
    }



}