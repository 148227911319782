import {
  Component,
  OnInit,
  Inject,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Contexto } from 'src/app/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import * as _ from 'underscore';
import { RastreoClienteModel } from 'src/app/entidades/rastreo-cliente';

@Component({
  selector: 'app-cierre-despacho-operativo-filter',
  templateUrl: './cierre-despacho-operativo-filter.component.html',
  styleUrls: ['./cierre-despacho-operativo-filter.component.scss']
})
export class CierreDespachoOperativoFilterComponent implements OnInit {
  forma: FormGroup;
  rastreosAgrupados: _.Dictionary<RastreoClienteModel[]>
  clientes: string[];
  proyectosClienteSeleccionado: string[];
  clienteSeleccionado: string = '';
  proyectoSeleccionadoNombre: string = '';
  grupoSeleccionado: RastreoClienteModel[];
  

  constructor(
    @Inject(MAT_DIALOG_DATA) public object: {rastreos: RastreoClienteModel[], rastreos2: RastreoClienteModel[]},
    public ventana: MatDialogRef<CierreDespachoOperativoFilterComponent>,
    private formBuilder: FormBuilder,
    private ctx: Contexto,
    private alerta: ServicioAlerta
  ) { }

  ngOnInit() {
    this.forma = this.formBuilder.group({
      cliente: ['', Validators.required],
      proyecto: ['', Validators.required]
    })
    this.formatData();
  }

  formatData(): void{
    this.rastreosAgrupados = _.chain(this.object.rastreos)
      .groupBy(c => c.socioNegocio)
      .value()

    
    this.clientes = _.chain(this.rastreosAgrupados)
    .keys()
    .sort()
    .value();

  }

  private agruparProyectosPorClave(grupo: any[]): { [clave: string]: any[] } {
    return _.chain(grupo)
    .groupBy(c => c.proyectoExternoClave)
    .value();
  }

  onClienteSeleccionado(clienteSeleccionado: string): void{
    this.grupoSeleccionado = this.rastreosAgrupados[clienteSeleccionado];

    this.clienteSeleccionado = this.grupoSeleccionado[0].cliente;

    const proyectosAgrupados = this.agruparProyectosPorClave(this.grupoSeleccionado);

    this.proyectosClienteSeleccionado = Object.keys(proyectosAgrupados);

    this.forma.get('proyecto').setValue('');
  }

  onProyectoSeleccionado(proyectoSeleccionado: string): void{
    const proyectosAgrupados = this.agruparProyectosPorClave(this.grupoSeleccionado);

    this.proyectoSeleccionadoNombre = proyectosAgrupados[proyectoSeleccionado][0].descripcionSocioNegocio;

  }

  cerrarYEnviarDatos(): void{
    if(this.forma.value && Object.keys(this.forma.value).length > 0){
      const datosParaEnviar = this.forma.value;
      this.ventana.close(datosParaEnviar);
    }else{
      this.alerta.mostrarAdvertencia('Selecciona un filtro para continuar')
    }
    
  }

  cerrarSinEnviar(): void{
    this.ventana.close();
  }

  eliminarFiltros(): void{
    this.ventana.close('delete');
  }
}
