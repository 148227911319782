import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MAT_DIALOG_DATA, MatSort, MatDialog } from '@angular/material';
import { RastreoCliente, RastreoClienteModel } from 'src/app/entidades/rastreo-cliente';
import * as moment from 'moment';
import { chain } from 'underscore';
import { CierreDespachoOperativoEmpleadoComponent } from '../cierre-despacho-operativo-empleado/cierre-despacho-operativo-empledo.component';

@Component({
    selector: 'app-cierre-despacho-operativo-selection',
    templateUrl: './cierre-despacho-operativo-selection-ad.component.html',
    styleUrls: ['./cierre-despacho-operativo-selection-ad.component.scss']
})
export class CierreDespachoOperativoSelectionAdComponent implements OnInit {
    fuenteDatos: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChild(MatSort) ordenador: MatSort;
    columnasMostradas = ['mesNum', 'jefeOperacion', 'servicio', 'aTiempo', 'fueraTiempo', 'diasDesfase', 'percentRentabilidad'];
    mes: string;
    numeroMes: number;
    diasDeToleranciaEmpleados: number;

    rastreosF: RastreoClienteModel[];

    constructor(
        @Inject(MAT_DIALOG_DATA) public object: { rastreos: RastreoClienteModel[], promedioAnterior: number, objetivo: number, mees: string, nuumeroMes: number, diasTolerancia: number },
        private ventana: MatDialog) {
    }

    ngOnInit(): void {
        this.mes = this.object.mees;

        if(this.mes.startsWith('W')){
            this.numeroMes = this.object.nuumeroMes
        }else{
            this.numeroMes = this.object.nuumeroMes + 1;
        }

        this.diasDeToleranciaEmpleados = this.object.diasTolerancia;

        this.rastreosF =       this.object.rastreos.filter(c => Number(moment(c.fechaEntrega).format('MM')) == this.numeroMes);

        const rastreos = chain(this.rastreosF)
            .groupBy(c => c.jefeOperacion)
            .map((x, y) => {
                return {
                    infos: y,
                    jefe: x[0].jefeOperacion,
                    data: x
                }
            })
            .sortBy(c => Number(c.jefe)).value();

        const monthsModel = chain(rastreos).map((month, key) => {
            // let desf = month.data.filter(d => moment(d.fechaEntrega).diff(d.fechaEnvioSAP, 'days') < 3)
            // let diasDif = 0;
            // desf.forEach(e => {
            //     let diff = moment(e.fechaEnvioSAP).diff(e.fechaEntrega, 'days');
            //     diasDif = + diff
            // });

            let desf = month.data.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') > this.diasDeToleranciaEmpleados)
            let diasDif = 0;
            desf.forEach(e => {
                let diff = moment(e.fechaEnvioSAP).diff(e.fechaEntrega, 'days');
                if (diff > this.diasDeToleranciaEmpleados) {
                    diasDif = + diff
                }
            });

            return {
                'mesNum': this.numeroMes,
                'jefeOperacion': month.jefe,
                'servicio': month.data.length,
                'aTiempo': month.data.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') <= this.diasDeToleranciaEmpleados).length,
                'fueraTiempo': month.data.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') > this.diasDeToleranciaEmpleados).length,
                'diasDesfase': diasDif,
                'percentRentabilidad': `${((month.data.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') > this.diasDeToleranciaEmpleados).length / month.data.length) * 100).toFixed(2)} %`,

            };
        }).value();

        this.fuenteDatos = new MatTableDataSource(monthsModel);
        this.fuenteDatos.sort = this.ordenador;
    }

    abrirFormaMes(jefeOperacion, messNum): void {
        const forma = this.ventana.open(CierreDespachoOperativoEmpleadoComponent, {
            data: { rastreos: this.rastreosF, objetivo: this.object.objetivo, empNomb: jefeOperacion, numMes: messNum, diasTolerancia: this.diasDeToleranciaEmpleados},
            panelClass: 'form-container',
            width: '70vw'
        });
    }

}