import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { InicioComponent } from './inicio/inicio.component';
import { LayoutModule } from '@angular/cdk/layout';
import {
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    MatCheckboxModule,
    MatMenuModule,
    MatGridListModule,
    MatTooltipModule,
    MatDialogModule,
    MAT_DIALOG_DEFAULT_OPTIONS,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    MatRadioModule,
    MatExpansionModule,
    MatCardModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MAT_SNACK_BAR_DEFAULT_OPTIONS,
    MAT_DATE_LOCALE,
    MatRippleModule,
    MatProgressBarModule,
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TextMaskModule } from 'angular2-text-mask';
import { ColorPickerModule } from 'ngx-color-picker';
import { ChartsModule } from 'ng2-charts';

import { Router } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DashboardComponent } from './paginas/dashboard/dashboard.component';
import { FormatoSocio } from './utilerias/formato-socio.pipe';
import { EncabezadoPipe } from './utilerias/titulo-encabezado-tabla.pipe';
import { LigaSeguraPipe } from './utilerias/liga-segura.pipe';
import { CargandoComponent } from './utilerias/overlay-cargando.service';
import { AgmCoreModule } from '@agm/core';
import { AnalisisComponent } from './paginas/dashboard/ventas-prospectadas/analisis/analisis.component';
import { GraficaVentaComponent } from './paginas/dashboard/ventas-prospectadas/grafica/grafica-venta.component';
import { GraficaVentasCanceladasComponent } from './paginas/dashboard/ventas-prospectadas/grafica-canceladas/grafica-canceladas.component';
import { VentasProspectadasComponent } from './paginas/dashboard/ventas-prospectadas/ventas-prospectadas.component';
import { VentasEmpleadoComponent } from './paginas/dashboard/ventas-prospectadas/ventas-por-empleado/ventas-empleado.component';
import { GridCardComponent } from './utilerias/contenedor/contenedor.component';
import { routing } from './app.routing';
import { FormaDatosProveedoresComponent } from './modulos/operaciones/componentes/datos-proveedores/datos-proveedores.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingModule } from './modulos/loading/loading.module';
import { TablaParetoComponent } from './paginas/dashboard/pareto/tabla/tabla.component';
import { ParetoComponent } from './paginas/dashboard/pareto/pareto.component';
import { GraficaParetoComponent } from './paginas/dashboard/pareto/grafica/grafica.component';
import { CurrencyPipe, DecimalPipe, DatePipe } from '@angular/common';
import { ExcelService } from './servicios/excel-service.service';
import { CatalogoPDFCotizacionGlobalSVComponent } from './modulos/flujo-ventas/componentes/cotizacion-venta/pdf-cotizacion-global-sv/catalogo/catalogo.component';
import { ControlArchivosComponent } from './utilerias/control-archivos/control-archivo.component';
import { FileDropDirective } from './utilerias/file-drop.directive';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { EmbarqueSelectorComponent } from './modulos/flujo-ventas/componentes/solicitud-embarque/selector/embarque-selector';
import { AsignarEmbarqueComponent } from './modulos/flujo-ventas/componentes/solicitud-embarque/asignar-solicitud-embarque/asignar-solicitud-embarque.component';
import { SolicitudEmbarqueComponent } from './modulos/flujo-ventas/componentes/solicitud-embarque/nueva-solicitud-embarque/solicitud-embarque.component';
import { BuscadorCatalogoComponent } from './modulos/flujo-ventas/componentes/solicitud-embarque/buscador-catalogo/buscador-catalogo.component';
import { RegistroManualMercanciaComponent } from './modulos/flujo-ventas/componentes/solicitud-embarque/registro-manual-mercancia/registro-manual-mercancia.component';
import { DescargaSolicitudEmbarqueComponent } from './modulos/flujo-ventas/componentes/solicitud-embarque/descarga-solicitud/descarga-solicitud.componente';
import { ModalTiposDocumentosNoFacturarComponent } from './modulos/flujo-ventas/componentes/modal-tipos-documento-no-facturar/modal-tipos-documento-no-facturar.component';
import { VentanaConfirmacionComponent } from './modulos/operaciones/componentes/ventana-confirmacion/ventana-confirmacion.component';
import { NgMatSearchBarModule } from 'ng-mat-search-bar';
import { DashboardPrincipalComponent } from './paginas/dashboard/principal/principal.component';
import { PerfectOrderComponent } from './paginas/dashboard/principal/perfect-order.component/perfect-order.component';
import { PerfectOrderDataComponent } from './paginas/dashboard/principal/perfect-order.component/perfect-order-data/perfect-order-data.component';
import { CierreDespachoOperativoDataComponent } from './paginas/dashboard/principal/cierre-despacho-operativo/cierre-despacho-operativo-data/cierre-despacho-operativo-data.component';
import { CierreDespachoOperativoComponent } from './paginas/dashboard/principal/cierre-despacho-operativo/cierre-despacho-operativo.component';
import { RentabilidadComponent } from './paginas/dashboard/principal/rentabilidad/rentabilidad.component';
import { RentabilidadDataComponent } from './paginas/dashboard/principal/rentabilidad/rentabilidad-data/rentabilidad-data.component';
import { FueraTiempoComponent } from './paginas/dashboard/principal/fuera-tiempo/fuera-tiempo.component';
import { CierreDespachoOperativoSelectionComponent } from './paginas/dashboard/principal/cierre-despacho-operativo/cierre-despacho-operativo-selection/cierre-despacho-operativo-selection-ad.component';
import { VentanaConfirmacionNuevaComponent } from './modulos/operaciones/componentes/ventana-confirmacion-nueva/ventana-confirmacion.component';
import { AsignarFechaCvComponent } from './modulos/flujo-ventas/componentes/asignar-fecha-cv/asignar-fecha-cv.component';
import { FormaFechaComponent } from './modulos/flujo-ventas/componentes/cotizacion-venta/formaFecha/forma.component';
import { FormaUbicacionComponent } from './modulos/flujo-ventas/componentes/cotizacion-venta/formaUbicacion/forma.component';
import { FormaAsignarEstatusCVComponent } from './modulos/flujo-ventas/componentes/cotizacion-venta/formaEstatus/forma.component';
import { FacturacionPComponent } from './paginas/dashboard/principal/facturacion-pendiente/facturacionP.component';
import { FacturacionPendienteData } from './paginas/dashboard/principal/facturacion-pendiente/facturacion-pendiente-data/facturacion-pendiente-data.component';
import { RentabilidadSelectionComponent } from './paginas/dashboard/principal/rentabilidad/rentabilidad-selection/rentabilidad-selection.component';
import { RentabilidadDataMoComponent } from './paginas/dashboard/principal/rentabilidad/rentabilidad-data-mo/rentabilidad-data-mo.component';
import { FacturacionPendienteSelection } from './paginas/dashboard/principal/facturacion-pendiente/facturacion-pendiente-mo/facturacion-pendiente-mo.component';
import { FacturacionPendienteGrupoSelection } from './paginas/dashboard/principal/facturacion-pendiente/facturacion-pendiente-mo-gru/facturacion-pendiente-mo-gru.component';
import { RentabilidadSelectionAdicionalComponent } from './paginas/dashboard/principal/rentabilidad/rentabilidad-selection-ad/rentabilidad-selection-ad.component';
import { CierreDespachoOperativoSelectionAdComponent } from './paginas/dashboard/principal/cierre-despacho-operativo/cierre-despacho-operativo-selection-ad/cierre-despacho-operativo-selection-ad.component';
import { PartidasCerradasComponent } from './paginas/dashboard/principal/partidas-cerradas/partidas-cerradas.component';
import { RentabilidadMesComponent } from './paginas/dashboard/principal/rentabilidad/rentabilidad-mes/rentabilidad-mes.component';
import { CierreDespachoOperativoMesMesComponent } from './paginas/dashboard/principal/cierre-despacho-operativo/cierre-despacho-operativo-mes/cierre-despacho-operativo-mes.component';
import { CierreDespachoOperativoEmpleadoComponent } from './paginas/dashboard/principal/cierre-despacho-operativo/cierre-despacho-operativo-empleado/cierre-despacho-operativo-empledo.component';
import { PartidasCerradasDataComponent } from './paginas/dashboard/principal/partidas-cerradas/partidas-cerradas-data/partidas-cerradas-data.component';
import { PartidasCerradasEmpleadoComponent } from './paginas/dashboard/principal/partidas-cerradas/partidas-cerradas-empleado/partidas-cerradas-empleado.component';
import { PartidasCerradasSelecComponent } from './paginas/dashboard/principal/partidas-cerradas/partidas-cerradas-selec/partidas-cerradas-selec.component';
import { PartidasCerradasclienteComponent } from './paginas/dashboard/principal/partidas-cerradas/partidas-cerradas-cliente/partidas-cerradas-cliente.component';
import { FormaUbicacionObligatorioComponent } from './modulos/flujo-ventas/componentes/cotizacion-venta/formaUbicacionObligatorio/forma.component';
import { VentasSAPComponent } from './paginas/dashboard/principal/Ventas-SAP/Ventas-SAP.component';
import { VentasSAPDataComponent } from './paginas/dashboard/principal/Ventas-SAP/Ventas-SAP-data/Ventas-SAP-data.component';
import { ComprasSAPComponent } from './paginas/dashboard/principal/Compras-SAP/Compras-SAP.component';
import { ComprasSAPDataComponent } from './paginas/dashboard/principal/Compras-SAP/Compras-SAP-data/Compras-SAP-data.component';
import { EstatusAsignadosComponent } from './modulos/flujo-ventas/componentes/cotizacion-venta/formaEstatus/estatus-asignados/estatus-asignados.component';
import { ConfirmacionCancelacionSVComponent } from './modulos/flujo-ventas/componentes/confirmacion-cancelacion-sv/confirmacion-cancelacion-sv.component';
import { RecargaDirective } from './directives/recarga.directive';
import { VentasSAPEntregadasComponent } from './paginas/dashboard/principal/Ventas-SAP/ventas-sap-entregadas/ventas-sap-entregadas.component';
import { VentasSAPTotalParcialComponent } from './paginas/dashboard/principal/Ventas-SAP/ventas-sap-total-parcial/ventas-sap-total-parcial.component';
import { VentasSAPFiltroComponent } from './paginas/dashboard/principal/Ventas-SAP/ventas-sapfiltro-component/ventas-sapfiltro-component.component';
import { CierreDespachoOperativoFilterComponent } from './paginas/dashboard/principal/cierre-despacho-operativo/cierre-despacho-operativo-filter/cierre-despacho-operativo-filter.component';
import { VentasSinEnviarSAPComponent } from './paginas/dashboard/principal/Ventas-SAP/ventas-sin-enviar-SAP/ventas-sin-enviar-SAP.component';
import { ModalMotivosNoFacturarComponent } from './modulos/motivo-documentos-enviados/componentes/modal-motivos-no-facturar/modal-motivos-no-facturar.component';
import { ComprasSinEnviarSAPComponent } from './paginas/dashboard/principal/Compras-SAP/compras-sin-enviar-SAP/compras-sin-enviar-SAP.component';
import { TiempoRespuestaClienteComponent } from './paginas/dashboard/principal/tiempo-respuesta-cliente/tiempo-respuesta-cliente.component';
import { BuscadorCvComponent } from './inicio/buscador-cv/buscador-cv.component';

@NgModule({
    declarations: [
        ModalMotivosNoFacturarComponent,
        AppComponent,
        FacturacionPendienteData,
        ConfirmacionCancelacionSVComponent,
        FacturacionPComponent,
        FormaAsignarEstatusCVComponent,
        FormaUbicacionComponent,
        FormaUbicacionObligatorioComponent,
        AsignarFechaCvComponent,
        FormaFechaComponent,
        VentanaConfirmacionNuevaComponent,
        FormatoSocio,
        InicioComponent,
        DashboardComponent,
        EncabezadoPipe,
        LigaSeguraPipe,
        CargandoComponent,
        AnalisisComponent,
        EstatusAsignadosComponent,
        GraficaVentaComponent,
        GraficaVentasCanceladasComponent,
        VentasProspectadasComponent,
        VentasEmpleadoComponent,
        GridCardComponent,
        FormaDatosProveedoresComponent,
        TablaParetoComponent,
        ParetoComponent,
        GraficaParetoComponent,
        RentabilidadSelectionComponent,
        RentabilidadDataMoComponent,
        CierreDespachoOperativoMesMesComponent,
        CierreDespachoOperativoEmpleadoComponent,
        PartidasCerradasSelecComponent,
        PartidasCerradasclienteComponent,
        CatalogoPDFCotizacionGlobalSVComponent,
        PartidasCerradasDataComponent,
        PartidasCerradasEmpleadoComponent,
        FacturacionPendienteGrupoSelection,
        FacturacionPendienteSelection,
        ControlArchivosComponent,
        FileDropDirective,
        RentabilidadSelectionAdicionalComponent,
        CierreDespachoOperativoSelectionAdComponent,
        EmbarqueSelectorComponent,
        AsignarEmbarqueComponent,
        BuscadorCatalogoComponent,
        SolicitudEmbarqueComponent,
        RegistroManualMercanciaComponent,
        DescargaSolicitudEmbarqueComponent,
        ModalTiposDocumentosNoFacturarComponent,
        RentabilidadMesComponent,
        VentanaConfirmacionComponent,
        PartidasCerradasComponent,
        DashboardPrincipalComponent,
        PerfectOrderComponent,
        PerfectOrderDataComponent,
        CierreDespachoOperativoComponent,
        VentasSAPComponent,
        VentasSAPDataComponent,
        ComprasSAPComponent,
        ComprasSAPDataComponent,
        CierreDespachoOperativoDataComponent,
        CierreDespachoOperativoSelectionComponent,
        RentabilidadComponent,
        RentabilidadDataComponent,
        FueraTiempoComponent,
        RecargaDirective,
        VentasSAPEntregadasComponent,
        VentasSAPTotalParcialComponent,
        CierreDespachoOperativoFilterComponent,
        VentasSAPFiltroComponent,
        VentasSinEnviarSAPComponent,
        ComprasSinEnviarSAPComponent,
        TiempoRespuestaClienteComponent,
        BuscadorCvComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AgmCoreModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBbRe_jx6FaIYeDXc8N00Sz9CYvGrqlogA',
            libraries: ['geometry']
        }),
        LayoutModule,
        FlexLayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        BrowserAnimationsModule,
        MatGridListModule,
        MatCardModule,
        MatMenuModule,
        routing,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        MatExpansionModule,
        ReactiveFormsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatFormFieldModule,
        MatTabsModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatSnackBarModule,
        MatChipsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        MatButtonToggleModule,
        MatToolbarModule,
        MatTooltipModule,
        MatRippleModule,
        FormsModule,
        TextMaskModule,
        ColorPickerModule,
        ChartsModule,
        NgxSpinnerModule,
        LoadingModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        NgMatSearchBarModule
    ],
    entryComponents: [
        ModalMotivosNoFacturarComponent,
        VentasSinEnviarSAPComponent,
        ComprasSinEnviarSAPComponent,
        EstatusAsignadosComponent,
        FacturacionPendienteData,
        RentabilidadSelectionComponent,
        VentanaConfirmacionNuevaComponent,
        FormaAsignarEstatusCVComponent,
        FormaUbicacionComponent,
        FormaUbicacionObligatorioComponent,
        ConfirmacionCancelacionSVComponent,
        AsignarFechaCvComponent,
        FormaFechaComponent,
        FacturacionPendienteGrupoSelection,
        RentabilidadSelectionAdicionalComponent,
        RentabilidadDataMoComponent,
        FacturacionPendienteSelection,
        PartidasCerradasEmpleadoComponent,
        PartidasCerradasSelecComponent,
        CierreDespachoOperativoSelectionAdComponent,
        CargandoComponent,
        RentabilidadMesComponent,
        CierreDespachoOperativoMesMesComponent,
        PartidasCerradasComponent,
        FormaDatosProveedoresComponent,
        CatalogoPDFCotizacionGlobalSVComponent,
        CierreDespachoOperativoEmpleadoComponent,
        PartidasCerradasclienteComponent,
        PartidasCerradasDataComponent,
        EmbarqueSelectorComponent,
        AsignarEmbarqueComponent,
        BuscadorCatalogoComponent,
        SolicitudEmbarqueComponent,
        RegistroManualMercanciaComponent,
        DescargaSolicitudEmbarqueComponent,
        ModalTiposDocumentosNoFacturarComponent,
        VentanaConfirmacionComponent,
        DashboardPrincipalComponent,
        PerfectOrderComponent,
        PerfectOrderDataComponent,
        VentanaConfirmacionComponent,
        CierreDespachoOperativoComponent,
        CierreDespachoOperativoDataComponent,
        CierreDespachoOperativoSelectionComponent,
        RentabilidadComponent,
        RentabilidadDataComponent,
        FueraTiempoComponent,
        VentasSAPDataComponent,
        ComprasSAPDataComponent,
        VentasSAPEntregadasComponent,
        VentasSAPTotalParcialComponent,
        CierreDespachoOperativoFilterComponent,
        VentasSAPFiltroComponent,
        TiempoRespuestaClienteComponent,
        BuscadorCvComponent
    ],
    providers: [
        CurrencyPipe,
        DatePipe,
        DecimalPipe,
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                closeOnNavigation: true,
                disableClose: true,
                autoFocus: true,
            },
        },
        ExcelService,
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: { duration: 2500, verticalPosition: 'top' },
        },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
    constructor(public router: Router) { }
}
