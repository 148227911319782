import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatDialog, MatSort } from '@angular/material';
import { RastreoCliente, RastreoClienteModel } from 'src/app/entidades/rastreo-cliente';
import * as moment from 'moment';
import { chain } from 'underscore';
import { RentabilidadMesComponent } from '../rentabilidad-mes/rentabilidad-mes.component';

@Component({
    selector: 'app-rentabilidad-data',
    templateUrl: './rentabilidad-data.component.html',
    styleUrls: ['./rentabilidad-data.component.scss']
})
export class RentabilidadDataComponent implements OnInit {
    fuenteDatos: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChild(MatSort) ordenador: MatSort;
    columnasMostradas = ['mesNum','periodo', 'venta', 'costo', 'rentabilidad', 'rojo', 'percentRentabilidad', 'meta'];
    rastreosNuevos: RastreoClienteModel[];

    constructor(
        @Inject(MAT_DIALOG_DATA) public object: { rastreos: RastreoClienteModel[], promedioAnterior: number, objetivo: number, yearC: number },
        public ventana: MatDialog) {
    }


    ngOnInit(): void {

        this.rastreosNuevos = this.object.rastreos;

        const months = chain(this.object.rastreos)
            .groupBy(c => moment(c.fechaCompromiso).format('MM'))
            .map((x, y) => {
                return {
                    monthNumber: y,
                    monthName: `${moment(y, 'MM').locale('es-mx').format('MMMM')}`,
                    data: x
                }
            })
            .sortBy(c => Number(c.monthNumber)).value();
 
        const monthsModel = chain(months).map((month, key) => {
            const venta = month.data.reduce((s, f) => s + f.importeVenta, 0);
            const costo = month.data.reduce((s, f) => s + f.importeCosto, 0);
            const rentabilidad = venta - costo;
            return {
                'mesNum': month.monthNumber,
                'periodo': month.monthName,
                'venta': venta,
                'costo': costo,
                'rentabilidad': rentabilidad,
                'rojo': rentabilidad < 0 ? true : false,
                'percentRentabilidad': `${((rentabilidad / venta) * 100).toFixed(2)} %`,
                'meta': `${this.object.objetivo.toFixed(2)} %`,
            };
        }).value();

        this.fuenteDatos = new MatTableDataSource(monthsModel);
        this.fuenteDatos.sort = this.ordenador;

    }

    abrirFormaMes(mesNum): void {
        const forma = this.ventana.open(RentabilidadMesComponent, {
            data: { rastreos: this.rastreosNuevos, objetivo: this.object.objetivo, messNum: mesNum, yearC: this.object.yearC},
            panelClass: 'form-container',
            width: '70vw'
        });
    }
}