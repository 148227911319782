import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
//import { Autenticacion } from 'src/app/autenticacion/autenticacion.service';
import { Mercancia } from 'src/app/entidades/mercancia';
import { SolicitudEmbarque } from 'src/app/entidades/solicitud-embarque';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-descarga-solicitud',
    templateUrl: './descarga-solicitud.componente.html',
    styleUrls: ['./descarga-solicitud.componente.scss'],
})
export class DescargaSolicitudEmbarqueComponent implements OnInit {
    claveProductoControl = new FormControl();
    claveUnidadControl = new FormControl();
    claveFraccionArancelariaControl = new FormControl();
    claveMaterialPeligrosoControl = new FormControl();
    claveEmbalajeControl = new FormControl();
    cantidadControl = new FormControl();
    valorUnitarioControl = new FormControl();
    monedaControl = new FormControl();
    largoControl = new FormControl();
    anchoControl = new FormControl();
    altoControl = new FormControl();
    pesoControl = new FormControl();
    pedimentoControl = new FormControl();
    uuidControl = new FormControl();

    solicitudEmbarque: SolicitudEmbarque;

    get ventanaNormal(): boolean {
        return this.parametros.tipo === 'normal';
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public parametros: { idDocumento: number; tipo: 'normal' | 'confirmacion'; },
        public dialogRef: MatDialogRef<DescargaSolicitudEmbarqueComponent>,
        public ventanaDocumento: MatDialog,
        private ctx: Contexto,
        public alerta: ServicioAlerta,
        //public autenticacion: Autenticacion
    ) {
    }

    ngOnInit(): void {
        this.claveProductoControl.setValue(true);
        this.claveUnidadControl.setValue(true);
        this.claveFraccionArancelariaControl.setValue(true);
        this.claveMaterialPeligrosoControl.setValue(true);
        this.claveEmbalajeControl.setValue(true);
        this.cantidadControl.setValue(true);
        this.valorUnitarioControl.setValue(true);
        this.monedaControl.setValue(true);
        this.largoControl.setValue(true);
        this.anchoControl.setValue(true);
        this.altoControl.setValue(true);
        this.pesoControl.setValue(true);
        this.pedimentoControl.setValue(true);
        this.uuidControl.setValue(true);

        //this.obtenerSolicitudEmbarque();
    }

    /*obtenerSolicitudEmbarque() {
        this.ctx.solicitudesEmbarque
            .obtenerSolicitudEmbarque(this.parametros.idDocumento, this.autenticacion.perfil.id)
            .subscribe(result => {
                this.solicitudEmbarque = result;
            }, error => console.error(error));
    }*/

    descargar() {
        const fileName = `Export`;
        const mercancias = this.construirMercancias(this.solicitudEmbarque.wares);
        const workSheet = XLSX.utils.json_to_sheet(mercancias);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, workSheet, fileName);
        const bin = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        this.dialogRef.close();
    }

    construirMercancias(mercancias: Mercancia[]): any[] {
        const obj = {};
        return mercancias.map((mercancia) => {
            if (this.claveProductoControl.value) { obj['CLAVE PRODUCTO'] = mercancia.claveProducto; }
            if (this.claveUnidadControl.value) { obj['CLAVE UNIDAD'] = mercancia.claveUnidad; }
            if (this.claveFraccionArancelariaControl.value) { obj['CLAVE FRACCION ARANCELARIA'] = mercancia.claveFraccionArancelaria; }
            if (this.claveMaterialPeligrosoControl.value) { obj['CLAVE MATERIAL PELIGROSO'] = mercancia.claveMaterialPeligroso; }
            if (this.claveEmbalajeControl.value) { obj['CLAVE EMBALAJE'] = mercancia.claveEmbalaje; }
            if (this.cantidadControl.value) { obj['CANTIDAD'] = mercancia.cantidad; }
            if (this.valorUnitarioControl.value) { obj['VALOR UNITARIO'] = mercancia.valorUnitario; }
            if (this.monedaControl.value) { obj['MONEDA'] = mercancia.moneda; }
            if (this.largoControl.value) { obj['LARGO'] = mercancia.largo; }
            if (this.anchoControl.value) { obj['ANCHO'] = mercancia.ancho; }
            if (this.altoControl.value) { obj['ALTO'] = mercancia.alto; }
            if (this.pesoControl.value) { obj['PESO EN KG'] = mercancia.peso; }
            if (this.pedimentoControl.value) { obj['PEDIMENTO'] = mercancia.pedimento; }
            if (this.uuidControl.value) { obj['UUID'] = mercancia.uuid; }
            return obj;
        });
    }
}
