import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatSort, MatDialog } from '@angular/material';
import { chain } from 'underscore';
import { Partidas } from 'src/app/entidades/partidas';

@Component({
    selector: 'app-partidas-cerradas-empleado',
    templateUrl: './partidas-cerradas-empleado.component.html',
    styleUrls: ['./partidas-cerradas-empleado.component.scss']
})
export class PartidasCerradasEmpleadoComponent implements OnInit {
    fuenteDatos: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChild(MatSort) ordenador: MatSort;
    columnasMostradas = [];
    rastreosNuevos: Partidas[];
    emp: string;
    filtroP = false;
    filtro: string;


    constructor(
        @Inject(MAT_DIALOG_DATA) public object: { rastreos: Partidas[], empleado: string, filtro: string  },
        private ventana: MatDialog) {
    }

    ngOnInit(): void {

        this.filtro = this.object.filtro

        if(this.object.filtro == 'Perdida'){
            this.filtroP = true;
            this.filtro = 'perdidas'
            this.columnasMostradas = ['cliente', 'proyecto', 'sv', 'fechaCotizacion', 'motivoPerdida'];
        }else{
            this.columnasMostradas = ['cliente', 'proyecto', 'sv', 'fechaCotizacion'];
            this.filtro = 'ganadas'
        }


        this.rastreosNuevos = this.object.rastreos.filter(e => e.empleado == this.object.empleado);
        const filtroPerdidas = this.rastreosNuevos.filter(e => e.estatus == this.object.filtro);
        this.emp = this.object.empleado;
        
        const months = chain(filtroPerdidas)
            .groupBy(e => e.proyecto)
            .map((x, y) => {
                return {
                    monthNumber: y,
                    cli: x[0].cliente,
                    pro: x[0].proyecto,
                    s: x[0].sv,
                    fc: x[0].fechaCotizacion,
                    motivoP: x[0].motivoPerdida
                }
            })
            .sortBy(c => c.cli).value();


        const monthsModel = chain(months).map((month, key) => {

            return {
                'cliente': month.cli,
                'proyecto': month.pro,
                'sv': month.s,
                'fechaCotizacion': month.fc,
                'motivoPerdida': month.motivoP
            };
        }).value();

        this.fuenteDatos = new MatTableDataSource(monthsModel);
        this.fuenteDatos.sort = this.ordenador;
    }

}