import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MAT_DIALOG_DATA, MatSort, MatDialog } from '@angular/material';
import { VentasPorEnviar } from 'src/app/entidades/VentasPorEnviar';
import * as XLSX from "xlsx";

@Component({
    selector: 'app-Ventas-SAP-data',
    templateUrl: './Ventas-SAP-data.component.html',
    styleUrls: ['./Ventas-SAP-data.component.scss']
})
export class VentasSAPDataComponent implements OnInit {
    fuenteDatos: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChild(MatSort) ordenador: MatSort;
    columnasMostradas =
        ['CodigoCliente',
            'NombreCliente',
            'Proveedor',
            'Proyecto',
            'Mercancia',
            'Servicio',
            'FechaDocumento',
            'CTFechaEnvioSAP',
            'SolicitudCotizacion',
            'Compra', 
            'Venta',
            'ImportedeVenta',
            'xMonitor',
            'EstatusSAP',
            'MonedaVenta'];
    Ventas: VentasPorEnviar[];
    titulo: string;
    data = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public object: { Ventas: VentasPorEnviar[], titulo: string },
        private ventana: MatDialog) {
    }

    ngOnInit(): void {
        this.titulo = this.object.titulo;
        this.Ventas = this.object.Ventas;
        this.fuenteDatos = new MatTableDataSource(this.Ventas);
        this.fuenteDatos.sort = this.ordenador;

    }

    exportExcel() {

        let tabla = document.getElementById('tabla');

        let wb = XLSX.utils.table_to_book(tabla, <XLSX.Table2SheetOpts>{
            sheet: `${this.titulo}`
        });
        XLSX.writeFile(wb, `Ventas ${this.titulo}.xlsx`);

    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.fuenteDatos.filter = filterValue.trim().toLowerCase();

        if (this.fuenteDatos.paginator) {
            this.fuenteDatos.paginator.firstPage();
        }
    }

}