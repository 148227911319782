import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatDialog, MatSort } from '@angular/material';
import { RastreoClienteModel } from 'src/app/entidades/rastreo-cliente';
import * as moment from 'moment';
import { chain } from 'underscore';

@Component({
    selector: 'app-rentabilidad-mes',
    templateUrl: './rentabilidad-mes.component.html',
    styleUrls: ['./rentabilidad-mes.component.scss']
})
export class RentabilidadMesComponent implements OnInit {
    fuenteDatos: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChild(MatSort) ordenador: MatSort;
    columnasMostradas = ['cliente', 'venta', 'costo', 'rentabilidad', 'rojo', 'percentRentabilidad', 'meta'];

    constructor(
        @Inject(MAT_DIALOG_DATA) public object: { rastreos: RastreoClienteModel[],  objetivo: number, messNum: number , yearC: number},
        public ventana: MatDialog) {
    }

    nomMes: string;

    ngOnInit(): void {
        const filtroAño = this.object.rastreos.filter(c => moment(c.fechaCompromiso).format('YYYY')  == String(this.object.yearC));
        const filtroMes = filtroAño.filter(c => Number(moment(c.fechaCompromiso).format('MM')) == this.object.messNum);
        const nombreMes = moment(this.object.messNum, 'MM').locale('es-mx').format('MMMM');
        const months = chain(filtroMes)
            .groupBy(c => c.cliente)
            .map((x, y) => {
                return {
                    monthNumber: y,
                    client: x[0].cliente,
                    data: x
                }
            })
            .sortBy(c => Number(c.monthNumber)).value();
 
        const monthsModel = chain(months).map((month, key) => {
            const venta = month.data.reduce((s, f) => s + f.importeVenta, 0);
            const costo = month.data.reduce((s, f) => s + f.importeCosto, 0);
            const rentabilidad = venta - costo;
            return {
                'cliente': month.client,
                'venta': venta,
                'costo': costo,
                'rentabilidad': rentabilidad,
                'rojo': rentabilidad < 0 ? true : false,
                'percentRentabilidad': `${((rentabilidad / venta) * 100).toFixed(2)} %`,
                'meta': `${this.object.objetivo.toFixed(2)} %`,
            };
        }).value();

        this.nomMes = nombreMes;

        this.fuenteDatos = new MatTableDataSource(monthsModel);
        this.fuenteDatos.sort = this.ordenador;

    }


}