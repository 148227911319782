import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { ServicioOverlayCargando } from 'src/app/utilerias/overlay-cargando.service';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { first } from 'rxjs/operators';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as moment from 'moment';
import { chain } from 'underscore';
import { Objetivo } from 'src/app/entidades/objetivo';
import { NuevasCotizaciones } from 'src/app/entidades/tiempo-respuesta';

@Component({
    selector: 'app-grafica-tiempo-respuesta-cliente',
    templateUrl: './tiempo-respuesta-cliente.component.html',
    styleUrls: ['./tiempo-respuesta-cliente.component.scss']
})
export class TiempoRespuestaClienteComponent implements OnInit, OnChanges {
    @Input() year: number = (new Date()).getFullYear();
    partidas: NuevasCotizaciones[];
    partidasF: NuevasCotizaciones[];
    objetivos: Objetivo[];
    objetivo: number;


    public barChartOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            yAxes: [{
                ticks: {
                    max: 100,
                    min: 0,
                    callback: (value, index, values) => {
                        return value;
                    }
                }
            }]
        },
        legend: {
            position: 'bottom',
            labels: {
                filter: function name(item, chart) {
                    return !item.text.includes('Servicios');
                },
            }
        },
        plugins: {
            datalabels: {
                formatter: (value, ctx) => {
                    const label = ctx.chart.data.labels[ctx.dataIndex];
                    label
                }
            }
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    return tooltipItem.yLabel

                }
            }
        },
        onClick: (event, active) => this.chartClicked({ event, active })
    };

    public barChartLabels = [];
    public barChartData: ChartDataSets[] = [
        {
            data: [], label: 'Hrs promedio', type: 'line', lineTension: 0, datalabels: {
                display: true,
                color: '#000',
                font: {
                    weight: 'bold',
                },
                formatter: (value, context) => {
                    return parseInt(value);
                },
                anchor: 'end',
                align: 'top',
                offset: 0,
            }, fill: false, pointRadius: 3
        },
        {
            data: [], label: 'Cotizaciones', type: 'line', lineTension: 0, datalabels: {
                display: true,
                color: '#000',
                font: {
                    weight: 'bold',
                },
                formatter: (value, context) => {
                    return parseInt(value);
                },
                anchor: 'end',
                align: 'top',
                offset: 0,
            }, fill: false, pointRadius: 3
        },
        { data: [], label: 'Objetivo', type: 'line', lineTension: 0, datalabels: { display: false }, fill: false, pointRadius: 0 }
    ];

    public barChartType: ChartType = 'bar';
    public barChartLegend = true;
    public barChartPlugins = [pluginDataLabels];
    public barChartColors = [
        {
            backgroundColor: []
        }
    ];


    constructor(private ctx: Contexto, public overlay: ServicioOverlayCargando, public ventana: MatDialog) {
        this.cargarDatos();
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(`Child OnChages: ${this.year}`);
        if (this.partidas) {
            this.actualizarDatos();
        }
    }

    ngOnInit() { }

    public chartClicked({ event, active }: { event: MouseEvent; active: {}[]; }): void {
        console.log(event, active);
        if (active.length > 0) {
            // const chart = active[0]['_chart'];
            // const columnName = chart.data.labels[chart.getElementAtEvent(event)[0]['_index']];
            // const colNum = active[0]['_index'];
            // const rastreos = chain(this.partidasF)
            //     .filter(c => moment(c.fechaCotizacion).locale('es-mx').format('MMMM') == columnName)
            // this.ventana.open(PartidasCerradasSelecComponent, {
            //     data: { rastreos: rastreos.value(), columnName, colNum },
            //     panelClass: 'form-container',
            //     width: '50vw',
            // });
        }
    }

    public chartHovered({ event, active }: { event: MouseEvent; active: {}[]; }): void {
        console.log(event, active);
    }

    cargarDatos(): void {
        this.ctx.documentosVenta
            .obtenerTiempoRespuesta()
            .pipe(first())
            .subscribe((response) => {
                this.partidas = response.servicios;
                this.objetivos = response.objetivos;
                this.actualizarDatos();
            });
    }


    actualizarDatos() {

        this.partidasF = this.partidas.filter(c => moment(c.fechaDocumento).format('YYYY') && moment(c.fechaDocumento).format('YYYY') == String(this.year));

        const months = chain(this.partidasF)
            .groupBy(c => moment(c.fechaDocumento).format('MM'))
            .map((x, y) => {
                return {
                    monthNumber: y,
                    monthName: `${moment(y, 'MM').locale('es-mx').format('MMMM')}`,
                    data: x,
                }
            })
            .sortBy(c => Number(c.monthNumber));


        const documentos = months.map(c => c.data)
            .map(c => {
                return (c)
            }).value();

        const monthKeys = months.map(c => c.monthName).value();

        if (this.objetivos.length == 0)
            this.objetivo = 0;
        else

            this.objetivo = this.objetivos.find(c => c.year == Number(`${this.year}`)).valor;

        const numeroColumnas = monthKeys.concat(monthKeys).length;

        const hrs = 'Hrs promedio';
        const cts = 'Cotizaciones';

        this.barChartLabels = monthKeys;

        this.barChartData.find(c => c.label == hrs).label;
        this.barChartData.find(c => c.label == cts).label;


        this.barChartData.find(c => c.label == 'Hrs promedio').data = documentos.map(c => c.reduce((s, f) => s + f.tiempo, 0) / c.length);
        this.barChartData.find(c => c.label == 'Cotizaciones').data = documentos.map(c => c.length);
        this.barChartData.find(c => c.label == 'Objetivo').data = Array(numeroColumnas).fill(this.objetivo);


        this.barChartData.find(c => c.label == hrs).backgroundColor = '#0070C0';
        this.barChartData.find(c => c.label == hrs).borderColor = '#0070C0';
        this.barChartData.find(c => c.label == hrs).pointBackgroundColor = '#0070C0';
        this.barChartData.find(c => c.label == hrs).pointHoverBackgroundColor = '#0070C0';
        this.barChartData.find(c => c.label == hrs).pointHoverBorderColor = '#0070C0';

        this.barChartData.find(c => c.label == cts).backgroundColor = '#0093c0';
        this.barChartData.find(c => c.label == cts).borderColor = '#0093c0';
        this.barChartData.find(c => c.label == cts).pointBackgroundColor = '#0093c0';
        this.barChartData.find(c => c.label == cts).pointHoverBackgroundColor = '#0093c0';
        this.barChartData.find(c => c.label == cts).pointHoverBorderColor = '#0093c0';

        this.barChartData.find(c => c.label == 'Objetivo').backgroundColor = '#00B050';
        this.barChartData.find(c => c.label == 'Objetivo').borderColor = '#00B050';



    }
}