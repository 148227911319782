import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MAT_DIALOG_DATA, MatDialog, MatSort } from '@angular/material';
import { RastreoClienteModel } from 'src/app/entidades/rastreo-cliente';
import * as moment from 'moment';
import { chain } from 'underscore';

@Component({
    selector: 'app-rentabilidad-empleado',
    templateUrl: './cierre-despacho-operativo-empleado.component.html',
    styleUrls: ['./cierre-despacho-operativo-empleado.component.scss']
})
export class CierreDespachoOperativoEmpleadoComponent implements OnInit {
    fuenteDatos: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChild(MatSort) ordenador: MatSort;
    columnasMostradas = ['cliente', 'numServicios', 'enviadosTiempo', 'fueraTiempo', 'diasDesfase', 'porcentaFueraTiempo'];

    constructor(
        @Inject(MAT_DIALOG_DATA) public object: { rastreos: RastreoClienteModel[], objetivo: number, numMes: number, empNomb: string, diasTolerancia: number },
        public ventana: MatDialog) {
    }

    nomMes: string;
    empNomb: string;
    diasDeTolerancia: number;

    ngOnInit(): void {
        this.diasDeTolerancia = this.object.diasTolerancia;
        //const filtroAño = this.object.rastreos.filter(c => moment(c.fechaCompromiso).format('YYYY') == moment(new Date()).format('YYYY'));
        const filtroMes = this.object.rastreos.filter(c => Number(moment(c.fechaEntrega).format('MM')) == this.object.numMes);
        const filtroEmpleado = filtroMes.filter(c => c.empleado == this.object.empNomb);
        const nombreMes = moment(this.object.numMes, 'MM').locale('es-mx').format('MMMM');
        const months = chain(filtroEmpleado)
            .groupBy(c => c.cliente)
            .map((x, y) => {
                return {
                    monthNumber: y,
                    cli: x[0].cliente,
                    data: x
                }
            })
            .sortBy(c => Number(c.cli)).value();

        const monthsModel = chain(months).map((month, key) => {

            let desf = month.data.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') < this.diasDeTolerancia)
            let diasDif = 0;
            desf.forEach(e => {
                let diff = moment(e.fechaEnvioSAP).diff(e.fechaEntrega, 'days');
                if (diff > 3) {
                    diasDif = + diff
                }
            });

            return {
                'cliente': month.cli,
                'numServicios': month.data.length,
                'enviadosTiempo': month.data.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') >= this.diasDeTolerancia).length,
                'fueraTiempo': month.data.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') < this.diasDeTolerancia).length,
                'diasDesfase': diasDif,
                'porcentaFueraTiempo': `${((month.data.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') < this.diasDeTolerancia).length / month.data.length) * 100).toFixed(2)} %`,

            };
        }).value();

        this.nomMes = nombreMes;
        this.empNomb = this.object.empNomb;

        this.fuenteDatos = new MatTableDataSource(monthsModel);
        this.fuenteDatos.sort = this.ordenador;

    }


}