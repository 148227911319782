import { RouterModule, Route, PreloadAllModules } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
//import { GuardiaAcceso, GuardiaPerfil } from './autenticacion/guardias.service';
import { DashboardComponent } from './paginas/dashboard/dashboard.component';
import { TiposUsuario } from './entidades/tipos-usuario';

const rutas: Route[] = [
    {
        path: '',
        children: [
            {
                path: '',
                component: DashboardComponent,
            }
        ],
    },
];

export const routing: ModuleWithProviders = RouterModule.forRoot(rutas, {
    useHash: true,
});
