import {
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    MatPaginator,
    MatSort,
    MatTableDataSource,
    MatDialog,
} from '@angular/material';
import { Documento } from 'src/app/entidades/documento';
import { Contexto } from 'src/app/api/contexto.service';
import { first } from 'rxjs/operators';
import { EstadoDocumento } from 'src/app/enumeraciones/enumeraciones';
import { Autorizaciones } from 'src/app/entidades/autorizaciones';
//import { Autenticacion } from 'src/app/autenticacion/autenticacion.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { VentanaConfirmacionComponent } from 'src/app/modulos/operaciones/componentes/ventana-confirmacion/ventana-confirmacion.component';
import { ModalMotivosNoFacturarComponent } from 'src/app/modulos/motivo-documentos-enviados/componentes/modal-motivos-no-facturar/modal-motivos-no-facturar.component';

@Component({
    selector: 'app-compras-sin-enviar-SAP',
    templateUrl: './compras-sin-enviar-SAP.component.html',
    styleUrls: ['./compras-sin-enviar-SAP.component.scss'],
})
export class ComprasSinEnviarSAPComponent implements OnInit {
    banderaGeneral = false;
    renglon = 0;
    autorizacion: Autorizaciones;


    @ViewChild(MatPaginator) paginador: MatPaginator;
    @ViewChild(MatSort) ordenador: MatSort;

    estadosDocumento: typeof EstadoDocumento = EstadoDocumento;

    fuenteDatosCompra: MatTableDataSource<Documento> = new MatTableDataSource(
        []
    );
    columnasMostradas = [
        'proyectoFolio',
        'folioRe',
        'folio',
        'folioSv',
        'fechaDocumento',
        'proveedorNombreCorto',
        'subtotal',
        'estadoDescripcion',
        'motivoNoEnvio',
        'opciones',
    ];
    cargando = true;

    public get diametro(): number {
        if (
            !this.fuenteDatosCompra ||
            this.fuenteDatosCompra.data.length === 0
        ) {
            return 50;
        }
        return 100;
    }

    constructor(private dialog: MatDialog,
        private ctx: Contexto,
        private alerta: ServicioAlerta,
        //public autenticacion: Autenticacion,
        private ventanaModal: MatDialog,
        private webapi: Contexto) { }

    async ngOnInit() {
        //await this.autenticacion.verificalPerfil();
        this.cargarCompras();
        this.cargarAutorizador();
    }

    cargarCompras(): void {
        this.webapi.documentosCompra
            .obtenerTodosSinProcesar()
            .toPromise()
            .then((documentos) => {
                this.fuenteDatosCompra = new MatTableDataSource(documentos);
                this.fuenteDatosCompra.paginator = this.paginador;
                this.fuenteDatosCompra.sort = this.ordenador;
                this.cargando = false;
            });
    }

    abrirModalMotivo(documento: Documento): void {
        this.dialog
            .open(ModalMotivosNoFacturarComponent, {
                data: {
                    documentoId: documento.id,
                    motivo: documento.motivoNoEnvio,
                    tipo: 'C',
                    cancelar: true,
                    obtener: 'Y',
                    guardar: false
                },
                panelClass: 'form-container',
            })
            .afterClosed()
            .pipe(first())
            .subscribe((actualizado) => this.cargarCompras());
    }


    cargarAutorizador() {
        this.ctx.autorizaciones.obtenerTipo('Compra').subscribe(a => {
            this.autorizacion = a;
        });
    }

    /*async abrirModalAutorizar(documento: Documento): Promise<void> {

        //obtengo credencial de usuario
        const usuarioLogIn = this.autenticacion.credencial.nombreEmpleado

        if (usuarioLogIn == this.autorizacion[0].nombre) {


            var respuesta = await this.ventanaModal
                .open(VentanaConfirmacionComponent, {
                    data: {
                        titulo: '¿Autorizar compra?',
                        botonVerdad: 'Si',
                        botonFalso: 'No',
                    },
                })
                .afterClosed()
                .toPromise()

            if (respuesta) {
                this.ctx.autorizaciones.autorizarNoFacturar(documento.id, 'Compra')
                    .toPromise()
                    .then((resp => {
                        if (resp) {
                            this.alerta.mostrarExito('Documento autorizado');
                        } else {
                            this.alerta.mostrarError('Error al autorizar documento');
                        }
                    }
                    ))
            }


        } else {
            this.alerta.mostrarError('Este usuario no tiene permiso para autorizar');

        }
    }*/

    filtrar(filterValue: string) {
        this.fuenteDatosCompra.filter = filterValue.trim().toLowerCase();
    }
}
