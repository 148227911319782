import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Contexto } from 'src/app/api/contexto.service';
import { first } from 'rxjs/operators';
import { MotivoCancelacion } from 'src/app/entidades/motivo-cancelacion';
import { MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-confirmacion-cancelacion-sv',
  templateUrl: './confirmacion-cancelacion-sv.component.html',
  styleUrls: ['./confirmacion-cancelacion-sv.component.scss']
})
export class ConfirmacionCancelacionSVComponent implements OnInit {
  controlMotivo = new FormControl();
  motivos$: Observable<MotivoCancelacion[]>;

  constructor(
    private ctx: Contexto,
    private modal: MatDialog,
    private ventana: MatDialogRef<ConfirmacionCancelacionSVComponent>
  ) { }

  ngOnInit() {
    this.motivos$ = this.ctx.motivosCancelacion.obtenerTodos();
  }

  confirmar() {

    this.ventana.close(1);

  }

}
