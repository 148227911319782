import { HttpClient } from "@angular/common/http";
import { RepositorioCatalogo } from "./repositorio-catalogo";
import { Observable, of } from "rxjs";
import { Respuesta } from "../entidades/respuesta";
import { TiposMotivos } from "../entidades/tipos-motivos";
import { TiposMotivosPorDocumentoVenta } from "../entidades/tipos-motivos-doc-venta";

export class RepositorioTiposMotivosDocumentoVenta extends RepositorioCatalogo<TiposMotivosPorDocumentoVenta> {

    constructor(http: HttpClient) {
        super(http, "TiposMotivosNoFacturarPorDocumento");
    }

    guardarTipoMotivosPorDocumentoVenta(tipo: string, documentoId: number,motivos: TiposMotivos[], comentario: string): Observable<Respuesta<TiposMotivos>>  {
      const ruta = `${this.ruta}/GuardarTipoMotivosNoFacturarPorDocumento/${documentoId}/${tipo}/${comentario}`;
      return this.clienteHttp.post<Respuesta<TiposMotivos>>(ruta, motivos);
    }

    obtenerTipoMotivosNoFacturarPorDocumento(documentoId: number, tipo: string): Observable<TiposMotivosPorDocumentoVenta[]>  {
      const ruta = `${this.ruta}/ObtenerTipoMotivosNoFacturarPorDocumento/${documentoId}/${tipo}`;
      return this.clienteHttp.get<TiposMotivosPorDocumentoVenta[]>(ruta);
    }

}
